import dynamic from 'next/dynamic';
import { FC, ReactElement, useMemo } from 'react';

import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary';
import {
  ContentfulObjectType,
  ContentfulPageSection,
  ContentfulPageSectionType,
} from 'src/data/Contentful/ContentfulTypes';
import type { WhyCarmaProps } from 'src/general/sections/WhyCarma/WhyCarmaLegacy';
import type { BusinessPartnersProps } from 'src/hygiene/sections/BusinessPartners/BusinessPartners';
import type { CarFinanceProps } from 'src/hygiene/sections/CarFinance/CarFinance';
import type { CarmaUSPProps } from 'src/hygiene/sections/CarmaUSP/CarmaUSP';
import type { CarouselOccasionProps } from 'src/hygiene/sections/CarouselOccasion/CarouselOccasion';
import type { HeroBannerProps } from 'src/hygiene/sections/HeroBanner/HeroBanner';
import type { HeroBannerProps as NewHeroBannerProps } from 'src/hygiene/sections/NewHeroBanner/HeroBanner';
import type { NextCarCtaProps } from 'src/hygiene/sections/NextCarCta/NextCarCta';
import type { RichTextProps } from 'src/hygiene/sections/RichText/RichText';
import { ProductListDataPayload } from 'src/types/CataloguePage.types';

import { FiveGreatThingsAboutFinanceProps } from '../FiveGreatThingsAboutFinance/FiveGreatThingsAboutFinance';
import { HowFinanceWorksHeroProps } from '../HowFinanceWorksHero/HowFinanceWorksHero';
import { LeftRightImageProps } from '../LeftRightImage/LeftRightImage';
import { TradeInBanner } from '../TradeInBanner/TradeInBanner';
import { FlexibleSectionManagerLegacy } from './FlexibleSectionManagerLegacy';
import { renderSection } from './helpers';

// Dynamic section imports.
const HeroBanner = dynamic<HeroBannerProps>(() =>
  import('src/hygiene/sections/HeroBanner/HeroBanner').then(({ HeroBanner }) => HeroBanner),
);
const NewHeroBanner = dynamic<NewHeroBannerProps>(() =>
  import('src/hygiene/sections/NewHeroBanner/HeroBanner').then(({ HeroBanner }) => HeroBanner),
);
const CarmaUSP = dynamic<CarmaUSPProps>(() =>
  import('src/hygiene/sections/CarmaUSP/CarmaUSP').then(({ CarmaUSP }) => CarmaUSP),
);

const WhyCarmaLegacy = dynamic<WhyCarmaProps>(() =>
  import('src/general/sections/WhyCarma/WhyCarmaLegacy').then(({ WhyCarmaLegacy }) => WhyCarmaLegacy),
);
const HowFinanceWorksHero = dynamic<HowFinanceWorksHeroProps>(() =>
  import('src/hygiene/sections/HowFinanceWorksHero/HowFinanceWorksHero').then(
    ({ HowFinanceWorksHero }) => HowFinanceWorksHero,
  ),
);
const FiveGreatThingsAboutFinance = dynamic<FiveGreatThingsAboutFinanceProps>(() =>
  import('src/hygiene/sections/FiveGreatThingsAboutFinance/FiveGreatThingsAboutFinance').then(
    ({ FiveGreatThingsAboutFinance }) => FiveGreatThingsAboutFinance,
  ),
);
const FinanceProviders = dynamic<HowFinanceWorksHeroProps>(() =>
  import('src/hygiene/sections/FinanceProviders/FinanceProviders').then(({ FinanceProviders }) => FinanceProviders),
);
const CarFinance = dynamic<CarFinanceProps>(() =>
  import('src/hygiene/sections/CarFinance/CarFinance').then(({ CarFinance }) => CarFinance),
);
const BusinessPartners = dynamic<BusinessPartnersProps>(() =>
  import('src/hygiene/sections/BusinessPartners/BusinessPartners').then(({ BusinessPartners }) => BusinessPartners),
);
const NextCarCta = dynamic<NextCarCtaProps>(() =>
  import('src/hygiene/sections/NextCarCta/NextCarCta').then(({ NextCarCta }) => NextCarCta),
);
const CarouselOccasion = dynamic<CarouselOccasionProps>(() =>
  import('src/hygiene/sections/CarouselOccasion/CarouselOccasion').then(({ CarouselOccasion }) => CarouselOccasion),
);
const ContentfulRichTextAccordionSection = dynamic(() =>
  import('src/hygiene/sections/ContentfulRichTextAccordionSection/ContentfulRichTextAccordionSection').then(
    ({ ContentfulRichTextAccordionSection }) => ContentfulRichTextAccordionSection,
  ),
);
const ContactUsHero = dynamic(() =>
  import('src/hygiene/sections/ContactUsHero/ContactUsHero').then(({ ContactUsHero }) => ContactUsHero),
);
const ContactUsGetInTouch = dynamic(() =>
  import('src/hygiene/sections/ContactUsGetInTouch/ContactUsGetInTouch').then(
    ({ ContactUsGetInTouch }) => ContactUsGetInTouch,
  ),
);
const RichText = dynamic<RichTextProps>(() =>
  import('src/hygiene/sections/RichText/RichText').then(({ RichText }) => RichText),
);
const LeftRightImage = dynamic<LeftRightImageProps>(() =>
  import('src/hygiene/sections/LeftRightImage/LeftRightImage').then(({ LeftRightImage }) => LeftRightImage),
);

const HighlightCarmaSponsorships = dynamic(() =>
  import('src/hygiene/sections/HighlightCarmaSponsorships/HighlightCarmaSponsorships').then(
    ({ HighlightCarmaSponsorships }) => HighlightCarmaSponsorships,
  ),
);

const TradeInUSPs = dynamic(() =>
  import('src/hygiene/sections/TradeInUSPs/TradeInUSPs').then(({ TradeInUSPs }) => TradeInUSPs),
);

const HowTradeInWorks = dynamic(() =>
  import('src/hygiene/sections/HowTradeInWorks/HowTradeInWorks').then(({ HowTradeInWorks }) => HowTradeInWorks),
);

const SocialProof = dynamic(() =>
  import('src/hygiene/sections/SocialProof/SocialProof').then(({ SocialProof }) => SocialProof),
);

const Benefit = dynamic(() => import('src/hygiene/sections/Benefit/Benefit').then(({ Benefit }) => Benefit));

const OurCommitment = dynamic(() =>
  import('src/hygiene/sections/OurCommitment/OurCommitment').then(({ OurCommitment }) => OurCommitment),
);

const FlexiblePayment = dynamic(() =>
  import('src/hygiene/sections/FlexiblePayment/FlexiblePayment').then(({ FlexiblePayment }) => FlexiblePayment),
);

interface SectionManagerProps {
  sections: ContentfulPageSection[];
  productTotal: Pick<ProductListDataPayload, 'total'>;
}

export const SectionManager: FC<SectionManagerProps> = ({ sections, productTotal }) => {
  const sectionComponents = useMemo(
    () =>
      sections
        .map((section) => {
          try {
            // renderSection is called in each switch case, instead of once outside, as this allows the type narrowing
            // of 'section' to work and ensures that renderSection is called with the correct arguments for the given
            // component.
            switch (section.__typename) {
              case ContentfulObjectType.SECTION_PAGE_SECTION:
                switch (section.sectionType) {
                  case ContentfulPageSectionType.HOMEPAGE_HERO:
                    return renderSection(HeroBanner, section, productTotal, ['total']);
                  case ContentfulPageSectionType.CAMPAIGN_HERO:
                    return renderSection(NewHeroBanner, section, productTotal, ['total']);
                  case ContentfulPageSectionType.USPS:
                    return renderSection(CarmaUSP, section);
                  case ContentfulPageSectionType.WHY_CARMA:
                    return renderSection(WhyCarmaLegacy, section);
                  case ContentfulPageSectionType.CAR_FINANCE:
                    return renderSection(CarFinance, section);
                  case ContentfulPageSectionType.HOW_FINANCE_WORKS_HERO:
                    return renderSection(HowFinanceWorksHero, section);
                  case ContentfulPageSectionType.FIVE_GREAT_THINGS_ABOUT_FINANCE:
                    return renderSection(FiveGreatThingsAboutFinance, section);
                  case ContentfulPageSectionType.FINANCE_PROVIDERS:
                    return renderSection(FinanceProviders, section);
                  case ContentfulPageSectionType.CAROUSEL_OCCASION:
                    return renderSection(CarouselOccasion, section);
                  case ContentfulPageSectionType.CONTACT_US_HERO:
                    return renderSection(ContactUsHero, section);
                  case ContentfulPageSectionType.CONTACT_US_GET_IN_TOUCH:
                    return renderSection(ContactUsGetInTouch, section);
                  case ContentfulPageSectionType.HIGHLIGHT_CARMA_SPONSORSHIPS:
                    return renderSection(HighlightCarmaSponsorships, section);
                  case ContentfulPageSectionType.TRADE_IN_BANNER:
                    return renderSection(TradeInBanner, section);
                  case ContentfulPageSectionType.TRADE_IN_USPS:
                    return renderSection(TradeInUSPs, section);
                  case ContentfulPageSectionType.HOW_TRADE_IN_WORKS:
                    return renderSection(HowTradeInWorks, section);
                  case ContentfulPageSectionType.SOCIAL_PROOF:
                    return renderSection(SocialProof, section);
                  case ContentfulPageSectionType.BENEFIT:
                    return renderSection(Benefit, section);
                  case ContentfulPageSectionType.OUR_COMMITMENT:
                    return renderSection(OurCommitment, section);
                  case ContentfulPageSectionType.FLEXIBLE_PAYMENT:
                    return renderSection(FlexiblePayment, section);
                  default:
                    return null;
                }
              case ContentfulObjectType.SECTION_FLEXIBLE_SECTION:
                return (
                  <ErrorBoundary key={section.sys.id}>
                    <FlexibleSectionManagerLegacy flexibleSection={section as any} />
                  </ErrorBoundary>
                );
              case ContentfulObjectType.SECTION_TRUSTED_PARTNERS:
                return renderSection(BusinessPartners, section, productTotal, ['header', 'logoListingCollection']);
              case ContentfulObjectType.SECTION_NEXT_CAR_CTA:
                return renderSection(NextCarCta, section, productTotal, [
                  'sectionTitle',
                  'sectionImage',
                  'ctaLabel',
                  'ctaUrl',
                  'total',
                ]);
              case ContentfulObjectType.SECTION_ACCORDIONS:
                return renderSection(ContentfulRichTextAccordionSection, section, productTotal, [
                  'title',
                  'firstAccordionOpen',
                  'faqsCollection',
                  'ctaButtonText',
                  'ctaButtonLink',
                ]);
              case ContentfulObjectType.SECTION_RICH_TEXT:
                return renderSection(RichText, section, productTotal, ['title', 'content']);
              case ContentfulObjectType.SECTION_LEFT_RIGHT_IMAGE:
                return renderSection(LeftRightImage, section, productTotal, [
                  'header',
                  'content',
                  'btnLabel',
                  'btnUrl',
                  'imgUrl',
                  'reverse',
                ]);
              default:
                return null;
            }

            // Prevent any exceptions encountered from stopping the page rendering, and just skip that section.
          } catch (e) {
            return null;
          }
        })
        .filter((x: ReactElement | null): x is ReactElement => !!x),
    [sections, productTotal],
  );

  return <>{sectionComponents}</>;
};
