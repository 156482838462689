import { FC } from 'react';

import { HeroTradeIn } from '../flexible-content/HeroTradeIn/HeroTradeIn';

export const TradeInBanner: FC = () => (
  <HeroTradeIn
    sectionData={{
      title: 'Sell my car the easy way',
      desktopBackgroundImgUrl: '/images/components/TradeInBanner/Trade-inBG-desktop.png',
      mobileBackgroundImgUrl: 'https://img.carma.com.au/ui-assets/TradeInBanner/Trade-inBG-mobile.jpg',
    }}
  />
);
